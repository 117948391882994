import api from './api'

const apioperator = {
    async resendVerification(axios, organizationId, operatorId) {
        const url = `/organizations/${organizationId}/operators/${operatorId}/resend`
        return axios
            .post(url)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apioperator
