<template>
    <div>
        <div class="hidden grid-cols-1 xl:grid">
            <div class="">
                <div v-if="summary !== null" class="flex flex-wrap xl:h-12 xl:flex-nowrap">
                    <div
                        v-for="tab in tabs"
                        :key="tab.key"
                        class="group flex w-full cursor-pointer select-none border-b border-l border-t bg-white px-4 py-3 text-left text-xs font-medium uppercase text-slate-500 last:rounded-tr last:border-r"
                        :class="{
                            'border-slate-200 hover:bg-slate-50': !isTabSelected(tab.key),
                            'border-b-slate-400': !isTabSelected(tab.key) && activeTab.key !== 'everyone',
                            '!cursor-default border-slate-400 border-b-slate-200 !bg-indigo-50': isTabSelected(tab.key),
                            'border-l-slate-400': activeParentTab.index === tab.index - 1,
                        }"
                        @click="setActiveTab(tab.key)"
                    >
                        <div class="flex grow justify-between">
                            <div class="my-auto flex">
                                <span
                                    class="my-auto block h-2 w-2 rounded-full"
                                    :class="getFromDictionary(`campaign.stages.${tab.key}.bgClass`)"
                                />
                                <span class="ml-1.5 leading-none">{{ tab.label }}</span>
                            </div>
                            <div class="my-auto">
                                {{ getTabRecordCount(tab.summaryPath) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="getParentTab(activeTab.key) !== null && getParentTab(activeTab.key).tabs.length > 0"
                    class="mr-[1px] flex flex-wrap overflow-hidden border-b border-r border-b-slate-400 border-r-slate-400 xl:h-12 xl:flex-nowrap"
                >
                    <!-- All Statuses Option -->
                    <div
                        class="group relative flex w-full select-none border-l border-r-slate-400 bg-white py-3 pl-4 text-left text-xs font-medium uppercase text-slate-500 first:border-l-slate-400 last:rounded-br last:border-r"
                        :class="{
                            'cursor-pointer transition duration-200 hover:bg-slate-50':
                                activeParentTab.key !== activeTab.key,
                            '!cursor-default !bg-indigo-50 bg-white': activeParentTab.key === activeTab.key,
                        }"
                        @click="setActiveTab(activeParentTab.key)"
                    >
                        <div class="z-20 flex grow justify-between">
                            <div class="flex">
                                <span
                                    class="m-auto block flex h-5 w-5 rounded text-center text-white"
                                    :class="getFromDictionary(`campaign.stages.${activeParentTab.key}.bgClass`)"
                                >
                                    <span class="m-auto text-xs"></span>
                                </span>
                                <span class="my-auto ml-2 leading-none">{{ activeParentTab.allLabel }}</span>
                            </div>
                            <div class="my-auto">
                                {{ getTabRecordCount(activeParentTab.summaryPath) }}
                            </div>
                        </div>

                        <div
                            class="absolute -right-4 top-0 z-10 h-12 w-12 rotate-45 border-r border-t border-b-slate-400 bg-white"
                            :class="{
                                'cursor-pointer transition duration-200 group-hover:bg-slate-50':
                                    activeParentTab.key !== activeTab.key,
                                '!cursor-default !bg-indigo-50 bg-white': activeParentTab.key === activeTab.key,
                            }"
                        />
                    </div>

                    <div
                        v-for="tab in activeParentTab.tabs"
                        :key="tab.key"
                        class="group relative flex w-full select-none border-r-slate-400 bg-white py-3 pr-1 text-left text-xs font-medium uppercase text-slate-500 first:border-l-slate-400 last:rounded-br last:border-r last:pr-4 [&:not(:first-child)]:pl-10"
                        :class="{
                            'cursor-pointer transition duration-200 hover:bg-slate-50': !isTabSelected(tab.key),
                            '!cursor-default !bg-indigo-50 bg-white': isTabSelected(tab.key),
                        }"
                        @click="setActiveTab(tab.key)"
                    >
                        <div class="z-20 flex grow justify-between">
                            <div class="flex">
                                <span
                                    class="m-auto block flex h-5 w-5 rounded text-center text-white"
                                    :class="getFromDictionary(`campaign.stages.${activeParentTab.key}.bgClass`)"
                                >
                                </span>
                                <span class="my-auto ml-2 leading-none">{{ tab.label }}</span>
                            </div>
                            <div class="my-auto">
                                {{ getTabRecordCount(tab.summaryPath) }}
                            </div>
                        </div>

                        <div
                            class="absolute -right-4 top-0 z-10 h-12 w-12 rotate-45 border-r border-t border-b-slate-400 bg-white"
                            :class="{
                                'cursor-pointer transition duration-200 group-hover:bg-slate-50': !isTabSelected(
                                    tab.key,
                                ),
                                '!cursor-default !bg-indigo-50 bg-white': isTabSelected(tab.key),
                            }"
                        />
                    </div>
                </div>

                <div v-else class="flex h-12 w-full">
                    <div
                        class="mr-[1px] grow rounded-br border-b border-l border-r bg-slate-50"
                        :class="{
                            'border-l-slate-400': activeTab.key === 'everyone',
                        }"
                    />
                </div>
            </div>
        </div>

        <div class="mt-4">
            <template v-if="activeTab.table === 'finance'">
                <ParticipantFinanceTable
                    :actions="participantActions"
                    :creators="creators"
                    :paging="paging"
                    :campaign="getCampaign"
                    :active-parent-tab="activeParentTab"
                    :active-child-tab="activeChildTab"
                    :active-tab="activeTab"
                    :tabs="tabs"
                    @paging-change="onPagingChange"
                    @reload="onReload"
                />
            </template>

            <template v-if="activeTab.table === 'payments'">
                <ParticipantPaymentTable
                    :actions="paymentActions"
                    :creators="payments"
                    :paging="paging"
                    :campaign="getCampaign"
                    :active-parent-tab="activeParentTab"
                    :active-child-tab="activeChildTab"
                    :active-tab="activeTab"
                    :tabs="tabs"
                    @paging-change="onPagingChange"
                    @reload="onReload"
                />
            </template>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import ParticipantFinanceTable from '@/partials/tables/ParticipantFinanceTable.vue'
import ParticipantPaymentTable from '@/partials/tables/ParticipantPaymentTable.vue'

import financeAPI from '../utils/api/api.campaign.finance'

export default {
    name: 'CampaignFinanceSubpage',

    components: {
        ParticipantFinanceTable,
        ParticipantPaymentTable,
    },

    props: {
        supercampaign: Object,
        campaignsById: Object,
    },

    data() {
        return {
            creators: null,
            payments: null,
            summary: null,

            paging: {
                totalPages: 0,
                perPage: 25,
                currentPage: 1,
            },

            state: {
                isLoaded: false,
            },

            activeChildTab: null,
            activeParentTab: null,
            activeTab: null,

            currentTable: '',

            participantActions: [
                {
                    id: 'create-schedule',
                    label: 'Create Schedule',
                    financeStatus: ['incompleteschedule'],
                    instructions: 'This will create a payment schedule for the selected creators',
                    options: [],
                    iconType: 'forward',
                },
            ],

            paymentActions: [
                {
                    id: 'verify-payment',
                    label: 'Verify payments',
                    paymentStatus: ['created'],
                    instructions: '',
                    options: [],
                    iconType: 'forward',
                },
                {
                    id: 'approve-payment',
                    label: 'Approve payments',
                    paymentStatus: ['verified'],
                    instructions: '',
                    options: [],
                    iconType: 'forward',
                },
                {
                    id: 'pay-payment',
                    label: 'Pay payments',
                    paymentStatus: ['approved'],
                    instructions: '',
                    options: [],
                    validation: {
                        rules: [{ type: 'remote', value: 'pay-payment' }],
                        messages: {
                            'invalid-payments': {
                                title: 'Payments without payee',
                                message: 'The following creators have payments that do not have a payee assigned:',
                            },
                        },
                    },
                    iconType: 'forward',
                },
                {
                    id: 'pay-payment-manual',
                    label: 'Mark as manually paid',
                    paymentStatus: ['approved'],
                    instructions: '',
                    options: [],
                    showWarning: true,
                    iconType: 'forward',
                },
                {
                    id: 'rollback-payment',
                    label: 'Revert to previous status',
                    paymentStatus: ['verified', 'approved', 'paid'],
                    instructions: 'Moves payments back to the previous status',
                    options: [],
                    showWarning: true,
                    validation: {
                        rules: [{ type: 'remote', value: 'rollback-payment' }],
                        messages: {
                            'invalid-payments': {
                                title: 'Payments cannot ge rolled back',
                                message: 'The following creators have payments that cannot be rolled back:',
                            },
                        },
                    },
                    iconType: 'backward',
                },
            ],

            tabs: {
                payable: {
                    key: 'payable',
                    summaryPath: 'payable.total',
                    index: 1,
                    label: 'Payable creators',
                    allLabel: 'All Payable',
                    bgClass: 'bg-sky-500',
                    tabs: [
                        {
                            index: 0,
                            key: 'payable.incompletepayee',
                            summaryPath: 'payable.incompletepayee',
                            label: 'Incomplete payee',
                            filters: [{ id: 'status', value: 'incompletepayee' }],
                            table: 'finance',
                        },
                        {
                            index: 1,
                            key: 'payable.nopayeeassigned',
                            summaryPath: 'payable.nopayeeassigned',
                            label: 'No Payee Assigned',
                            filters: [{ id: 'status', value: 'nopayeeassigned' }],
                            table: 'finance',
                        },
                        {
                            index: 2,
                            key: 'payable.incompleteschedule',
                            summaryPath: 'payable.incompleteschedule',
                            label: 'Incomplete schedule',
                            filters: [{ id: 'status', value: 'incompleteschedule' }],
                            table: 'finance',
                        },
                        {
                            index: 3,
                            key: 'payable.fullyscheduled',
                            summaryPath: 'payable.fullyscheduled',
                            label: 'Fully scheduled',
                            filters: [{ id: 'status', value: 'fullyscheduled' }],
                            table: 'finance',
                        },
                        {
                            index: 4,
                            key: 'payable.paid',
                            summaryPath: 'payable.paid',
                            label: 'Paid',
                            filters: [{ id: 'status', value: 'paid' }],
                            table: 'finance',
                        },
                    ],
                    filters: [],
                    table: 'finance',
                },
                payments: {
                    key: 'payments',
                    summaryPath: 'payments.total',
                    index: 2,
                    label: 'Payments',
                    allLabel: 'All Payments',
                    bgClass: 'bg-emerald-500',
                    tabs: [
                        {
                            index: 0,
                            key: 'payments.created',
                            summaryPath: 'payments.created',
                            label: 'Created',
                            filters: [{ id: 'status', value: 'created' }],
                            table: 'payments',
                        },
                        {
                            index: 1,
                            key: 'payments.verified',
                            summaryPath: 'payments.verified',
                            label: 'Verified',
                            filters: [{ id: 'status', value: 'verified' }],
                            table: 'payments',
                        },
                        {
                            index: 2,
                            key: 'payments.approved',
                            summaryPath: 'payments.approved',
                            label: 'Approved',
                            filters: [{ id: 'status', value: 'approved' }],
                            table: 'payments',
                        },
                        {
                            index: 3,
                            key: 'payments.pending',
                            summaryPath: 'payments.pending',
                            label: 'Pending',
                            filters: [{ id: 'status', value: 'pending' }],
                            table: 'payments',
                        },
                        {
                            index: 4,
                            key: 'payments.error',
                            summaryPath: 'payments.error',
                            label: 'Error',
                            filters: [{ id: 'status', value: 'error' }],
                            table: 'payments',
                        },
                        {
                            index: 5,
                            key: 'payments.paid',
                            summaryPath: 'payments.paid',
                            label: 'Paid',
                            filters: [{ id: 'status', value: 'paid' }],
                            table: 'payments',
                        },
                    ],
                    filters: [],
                    table: 'payments',
                },
            },
        }
    },

    created() {
        this.getSummary(this.$route.params.campaign_id)
        this.setActiveTab('payable')
    },

    computed: {
        ...mapGetters(['getLanguageFromCode', 'getFromDictionary']),

        getCampaign() {
            return this.campaignsById[this.$route.params.campaign_id]
        },
    },

    methods: {
        getTabRecordCount(key) {
            const recordCount = _.get(this.summary, key)
            if (!recordCount) {
                return 0
            }
            return recordCount
        },

        getTab(key) {
            return _.get(this.tabs, key)
        },

        isTabSelected(key) {
            return this.activeTab.key === key || this.activeTab.key.split('.')[0] === key
        },

        getParentTab(key) {
            return this.tabs[key.split('.')[0]]
        },

        setActiveTab(key) {
            this.paging.currentPage = 1

            const keyParts = key.split('.')

            if (keyParts.length === 1) {
                this.activeTab = _.get(this.tabs, key)
            } else {
                this.activeTab = _.find(this.tabs[keyParts[0]].tabs, (item) => item.key === key)
            }

            if (this.currentTable !== this.activeTab.table) {
                this.paging.currentPage = 1
                this.paging.sortby = ''
                this.paging.sortdir = ''
            }

            this.currentTable = this.activeTab.table

            this.activeParentTab = _.get(this.tabs, keyParts[0])
            if (keyParts[1]) this.activeChildTab = this.activeTab
            else this.activeChildTab = null

            this.getFilteredRows(this.$route.params.campaign_id)
        },

        async getSummary(campaignId) {
            const result = await financeAPI.getFinanceSummary(this.$axios, campaignId)

            if (result.success) {
                this.summary = result.value.summary
                this.state.isLoaded = true
            }
        },

        async getFilteredRows(campaignId) {
            let result = null

            const pageFilters = {
                paging: {
                    perPage: this.paging.perPage,
                    currentPage: this.paging.currentPage,
                    sortby: this.paging.sortby,
                    sortdir: this.paging.sortdir,
                    filters: this.activeTab.filters,
                },
            }

            if (this.activeTab.table === 'finance') {
                result = await financeAPI.findPayableParticipants(this.$axios, campaignId, pageFilters)
            } else if (this.activeTab.table === 'payments') {
                result = await financeAPI.findPayments(this.$axios, campaignId, pageFilters)
            } else {
                return
            }

            if (result.success) {
                if (this.activeTab.table === 'finance') {
                    this.creators = result.value.records
                } else if (this.activeTab.table === 'payments') {
                    this.payments = result.value.records
                } else {
                    return
                }

                this.paging.perPage = result.value.paging.perPage
                this.paging.totalRecords = result.value.paging.totalRecords
                this.paging.totalPages = Math.ceil(this.paging.totalRecords / this.paging.perPage)

                if (this.paging.totalPages === 0) {
                    this.paging.totalPages = 0
                }
            }
        },

        onPagingChange(updatedPaging) {
            _.assign(this.paging, updatedPaging)
            this.getFilteredRows(this.$route.params.campaign_id)
        },

        onReload() {
            this.getFilteredRows(this.$route.params.campaign_id)
            this.getSummary(this.$route.params.campaign_id)
        },
    },
}
</script>
