<script setup>
import _ from 'lodash'
import { ref, computed, onMounted } from 'vue'
import { axios } from '@/utils/axios'
import participantStagesAPI from '@/utils/api/api.campaign.participant.stages'
// import participantAPI from '@/utils/api/api.campaign.participant'
import supercampaignAPI from '@/utils/api/api.supercampaign'

import Tasks from '@/partials/campaigns/Tasks.vue'
import Contracting from '@/partials/campaigns/Contracting.vue'
import Finance from '@/partials/campaigns/Finance.vue'
import ManageBids from '@/partials/campaigns/ManageBids.vue'

import {
    Select as SelectRoot,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'

const props = defineProps({
    participants: {
        type: Array,
        required: true,
    },
    selectedParticipantId: {
        type: String,
        required: false,
    },
})

const state = ref({
    activeTab: 'tasks',
    stages: {
        isLoaded: false,
    },
})

const selectedParticipant = ref(null)
const supercampaign = ref(null)
// const linkableUsers = ref(null)

const canChangeDeliverableType = computed(() => {
    return selectedParticipant.value && ['prospect'].includes(selectedParticipant.value.stage)
})

// Stages functionality
const resetStages = async () => {
    if (!selectedParticipant.value) return

    const stagesResp = await participantStagesAPI.getStages(axios, selectedParticipant.value.id)

    const currentStage = stagesResp.value.allStages.stage
    delete stagesResp.value.allStages.stage

    selectedParticipant.value.stages = stagesResp.value.allStages
    if (currentStage) {
        selectedParticipant.value.stage = currentStage
    }
}

const getParticipantStages = async () => {
    if (!selectedParticipant.value.stages) {
        await resetStages()
    }

    state.value.stages.isLoaded = true
}

// Handlers for sub-component events
const onContractChange = () => {
    resetStages()
}

const onBidChange = () => {
    resetStages()
}

const onParticipantSelect = async (participant) => {
    selectedParticipant.value = participant
    state.value.stages.isLoaded = false

    if (participant && (!supercampaign.value || participant.campaign.parent_id !== supercampaign.value.id)) {
        const { value } = await supercampaignAPI.getSuperCampaign(axios, participant.campaign.parent_id, true, true)
        supercampaign.value = value
    }

    await getParticipantStages()
}

onMounted(() => {
    if (props.selectedParticipantId) {
        const matchedParticipant = _.find(props.participants, (participant) => {
            return participant.id === props.selectedParticipantId
        })

        if (matchedParticipant) {
            onParticipantSelect(matchedParticipant)
            return
        }
    }

    if (props.participants?.length > 0) {
        onParticipantSelect(_.first(props.participants))
    }
})

const tabs = ref([
    {
        id: 'bids',
        label: 'Bids',
        condition: () => selectedParticipant.value,
    },
    {
        id: 'tasks',
        label: 'Tasks',
        condition: () => true,
    },
    {
        id: 'contract',
        label: 'Contracting',
        condition: () =>
            selectedParticipant.value &&
            ['contracting', 'preparation', 'delivery'].includes(selectedParticipant.value.stage),
    },
    {
        id: 'finance',
        label: 'Finance',
        condition: () =>
            selectedParticipant.value &&
            ['contracting', 'preparation', 'delivery'].includes(selectedParticipant.value.stage),
    },
])

const stages = computed(() => {
    return _.filter(selectedParticipant.value.stages, (item) => item.is_disabled === false)
})

// Add this computed
const visibleTabs = computed(() => {
    return tabs.value.filter((tab) => tab.condition())
})
</script>

<template>
    <div class="flex justify-between">
        <div>
            <div v-if="selectedParticipant" class="space-y-1 text-sm">
                <div class="flex h-14 divide-x !divide-slate-200 rounded border border-slate-200">
                    <div v-for="stage in stages" :key="stage.id" class="flex pl-3 pr-6 capitalize">
                        <div v-if="stage.completed_at !== null" class="my-auto mr-2 flex">
                            <div class="flex rounded bg-emerald-100 p-1 text-emerald-600">
                                <font-awesome-icon :icon="['fas', 'check']" class="m-auto h-3.5 w-3.5" />
                            </div>
                        </div>
                        <div v-else class="my-auto mr-2 flex">
                            <div class="flex rounded bg-amber-100 p-1 text-amber-600">
                                <font-awesome-icon :icon="['fas', 'hourglass']" class="m-auto h-3.5 w-3.5" />
                            </div>
                        </div>
                        <div class="my-auto">
                            <div class="mb-0.5 font-semibold leading-none">{{ stage.id }}</div>
                            <div class="text-xs leading-none">{{ stage.status }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <SelectRoot v-model="selectedParticipant" @update:model-value="onParticipantSelect">
                <SelectTrigger class="h-14 w-[240px]">
                    <SelectValue placeholder="Select a campaign" class="block" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem v-for="participant in participants" :key="participant.id" :value="participant">
                            <div class="flex flex-col">
                                <div class="mb-0.5 text-left font-medium leading-none">
                                    {{ participant.campaign.title }}
                                </div>
                                <div class="text-left text-xs leading-none">
                                    {{ $moment(participant.campaign.created_at).format('MMM Do, YYYY') }}
                                </div>
                            </div>
                        </SelectItem>
                    </SelectGroup>
                </SelectContent>
            </SelectRoot>
        </div>
    </div>

    <template v-if="selectedParticipant">
        <div class="relative mb-5 mt-8">
            <div class="absolute bottom-0 h-px w-full bg-slate-200" aria-hidden="true" />

            <ul class="no-scrollbar relative -mx-4 flex flex-nowrap text-sm font-medium sm:-mx-6 lg:-mx-8">
                <li
                    v-for="tab in visibleTabs"
                    :key="tab.id"
                    class="mr-6 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                >
                    <a
                        class="block cursor-pointer whitespace-nowrap py-3"
                        :class="{
                            'border-b-2 border-indigo-500 text-indigo-500': state.activeTab === tab.id,
                        }"
                        @click="state.activeTab = tab.id"
                    >
                        {{ tab.label }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="state.activeTab === 'tasks'" class="mt-8">
            <div class="grid gap-4 md:grid-cols-12">
                <div class="col-span-12 md:col-span-4">
                    <h2 class="font-semibold text-slate-800">Deliverables</h2>
                    <div class="space-y-2 text-sm">
                        <p>Let's determine what we want the creator to do for the campaign.</p>

                        <div
                            v-if="
                                canChangeDeliverableType && selectedParticipant?.stages?.prospect?.custom_deliverables
                            "
                            class="mt-5 flex items-center"
                        >
                            <div class="form-switch">
                                <input
                                    id="custom_tasks_switch"
                                    v-model="selectedParticipant.stages.prospect.custom_deliverables"
                                    type="checkbox"
                                    class="sr-only"
                                    :disabled="!state.stages.isLoaded"
                                />
                                <label class="bg-slate-400" for="custom_tasks_switch">
                                    <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                    <span class="sr-only">Toggle</span>
                                </label>
                            </div>

                            <div
                                class="ml-2 text-sm"
                                :class="
                                    selectedParticipant?.stages?.prospect?.custom_deliverables
                                        ? 'text-slate-500'
                                        : 'italic text-slate-400'
                                "
                            >
                                {{
                                    selectedParticipant?.stages?.prospect?.custom_deliverables
                                        ? 'Using custom deliverables'
                                        : 'Using default deliverables'
                                }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 md:col-span-8 md:pl-10">
                    <Tasks
                        v-if="selectedParticipant"
                        :platforms="[selectedParticipant.platform_id]"
                        :supercampaign-id="selectedParticipant.campaign.parent_id"
                        :campaign="selectedParticipant.campaign"
                        :participant-id="selectedParticipant.id"
                        :allow-add="true"
                        :allow-edit="true"
                        :hide-empty-platform="false"
                        :show-empty-warning="true"
                    />
                </div>
            </div>
        </div>

        <div v-if="state.activeTab === 'contract' && state.stages.isLoaded">
            <Contracting
                :supercampaign="supercampaign"
                :campaign="selectedParticipant.campaign"
                :participant-id="selectedParticipant.id"
                :participant="selectedParticipant"
                @contract-change="onContractChange"
            />
        </div>

        <div v-if="state.activeTab === 'finance' && state.stages.isLoaded">
            <Finance
                :supercampaign="supercampaign"
                :campaign="selectedParticipant.campaign"
                :participant="selectedParticipant"
            />
        </div>

        <div v-if="state.activeTab === 'bids' && state.stages.isLoaded">
            <ManageBids
                :supercampaign-id="supercampaign.id"
                :campaign="selectedParticipant.campaign"
                :participant="selectedParticipant"
                :on-bid-change="onBidChange"
            />
        </div>
    </template>
</template>
